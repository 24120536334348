import React, { useState, useEffect } from "react";
import Layout from "../../components/chat-channel/Layout";
import { Link } from "gatsby";
import { VideoElement2 } from "../../components/video";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { RequestForm } from "../../components/form";
import Container from "../../components/chat-channel/Container";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import { useMedia } from "use-media";
import { useSwipeable, Swipeable } from "react-swipeable";
import { OnboardingSlider } from "../conversational-ai-platform";
import { OnboardingMobile } from "../conversational-ai-platform";
import { Features } from "../conversational-ai-platform";
import { AppsIntegrations } from "../conversational-ai-platform";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { Tabs, Tab, TabPanel, TabList, TabProvider } from "react-web-tabs";
import {
  ArrowRightICon,
  MobNextArrowIcon,
} from "../../components/common/Icons";
import { redirectToWithUtm } from "../../components/Header";

const topImage = require("../../assets/images/usecases/con_ai_bot/ws_header.png");
const VidImg = require("../../assets/images/usecases/con_ai_bot/con_ai_vid.png");

const img = require("../../assets/img/feature/home/iso_sec_img.png");
const img_mob = require("../../assets/img/feature/home/iso_sec_img_mob.png");
const customerBanner = require("../../assets/img/feature/home/header_customer_banner.svg");
const bannerImageMobile = require("../../assets/img/feature/home/header_customer_banner_mobile.svg");

const FeatureSlack = require("../../assets/images/features_images/features_slack.png");
const FeatureTeams = require("../../assets/images/features_images/features_teams.png");
const FeatureChat = require("../../assets/images/features_images/features_chat.png");

const AllFeatureImg1 = require("../../assets/images/usecases/con_ai_bot/ws_features_1.png");
const AllFeatureImg2 = require("../../assets/images/usecases/ai_ticketing/ticket_features_2.png");
const AllFeatureImg3 = require("../../assets/images/usecases/v_agent/virtual_features_3.png");
const AllFeatureImg4 = require("../../assets/images/usecases/con_ai_bot/ws_onboarding_5.png");
const AllFeatureImg5 = require("../../assets/images/usecases/v_agent/virtual_features_5.png");

const OnboardSlide = require("../../assets/images/onboarding/onboarding_slider_1.png");
const OnboardSlide1 = require("../../assets/images/onboarding/onboarding_slider_2.png");
const OnboardSlide2 = require("../../assets/images/usecases/v_agent/virtual_onboarding_3.png");
const OnboardSlide3 = require("../../assets/images/onboarding/onboarding_slider_4.png");
const OnboardSlide4 = require("../../assets/images/usecases/con_ai_bot/ws_onboarding_5.png");
const OnboardArrow = require("../../assets/images/onboarding/onboarding_arrow.svg");

const unlock = require("../../assets/images/usecases/con_ai_bot/ws_1.png");
const password = require("../../assets/images/usecases/con_ai_bot/ws_2.png");
const user = require("../../assets/images/usecases/con_ai_bot/ws_3.png");
const access = require("../../assets/images/usecases/con_ai_bot/ws_4.png");
const asset = require("../../assets/images/usecases/con_ai_bot/ws_5.png");
const leave = require("../../assets/images/usecases/con_ai_bot/ws_7_1.png");
const onboarding = require("../../assets/images/usecases/con_ai_bot/ws_6.png");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Free Workplace Support  Bot | Workativ Assistant Chatbot"
        description="Use Workplace Support Bot to automate workplace support. Transform your employee experience with AI and workflow automation powered digital assistant solutions and increase employee productivity."
        keywords={[
          "HR chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "helpdesk chatbot free trial",
          "automation",
        ]}
        ogImage={topImage}
      />
      <section className="it_support_chatbot  container_trial integrations_container landing_page_chatbot">
        <TabProvider defaultTab="section1" vertical>
          <Container>
            <Layout backgroundColor={"landing_bg"} logoFor="ASSISTANT">
              <RegisterSection
                rightImage={topImage}
                backgroundColor={"landing_bg"}
                altImage={"workplace support chatbot"}
                iD={"chatbot"}
                bannerImage={customerBanner}
                bannerImageMobile={bannerImageMobile}
              >
                <RegisterSection.FormWithContent>
                  <RegisterSection.MainHeader>
                    Transform your workplace support with Workplace Support Bot
                  </RegisterSection.MainHeader>
                  <RegisterSection.SubHeader>
                    Workativ helps you deliver autonomous support to employee
                    enquires and service requests using advanced Chatbot with
                    in-built Workflow Automation that resolves issues in
                    seconds.
                  </RegisterSection.SubHeader>
                </RegisterSection.FormWithContent>
              </RegisterSection>

              {isSmall ? (
                <OnboardingMobile
                  header={onboardoingSliderData.header.mobile}
                  subHeader={onboardoingSliderData.subHeader.mobile}
                  sliders={onboardoingSliderData.sliders}
                  additionalContainerClass="padding-top-30 float-left"
                />
              ) : (
                <OnboardingSlider
                  header={onboardoingSliderData.header.desktop}
                  subHeader={onboardoingSliderData.subHeader.desktop}
                  sliders={onboardoingSliderData.sliders}
                  additionalContainerClass="padding-top-50"
                />
              )}

              <Features
                header={featureComponents.header}
                mobileHeader={featureComponents.mobileHeader}
                dataToRender={featureComponents.sliders}
                autoplay={false}
              />

              <AppsIntegrations
                header={
                  <>
                    <h3 className="font-section-header mb-2">
                      Connect Workplace Support Bot with 70+ apps, 600+ actions,
                      and 1000s of automations instantly. No coding.
                    </h3>
                  </>
                }
              />

              <section className="center_image_home cards_landing_page pb-0 mb-0">
                <div className="container">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="main-slider-left">
                        <h3 className="font-section-header">
                          Your data is yours and you control it
                        </h3>
                        <p className="font-section-normal-text">
                          Workativ has implemented robust security processes and
                          controls that are in compliance with industry-leading
                          standards and regulations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="automation_steps iso">
                <div className="container">
                  {isSmall ? (
                    <picture>
                      <img className="center" src={img_mob} />
                    </picture>
                  ) : (
                    <picture>
                      <img className="center" src={img} />
                    </picture>
                  )}
                </div>
              </section>
              <div className="ahref-wrapper">
                <Link
                  to="https://workativ.com/security"
                  className="url_manipulation font-section-normal-text-medium"
                >
                  Learn more about our security{" "}
                </Link>
              </div>
              <div className="container">
                <div className="usecase_blog_container">
                  <h3 class="font-section-sub-header-small-bold-v2">
                    Must Read Blogs
                  </h3>
                  <div className="usecase_bottom_blog">
                    <ul>
                      <li>
                        <a
                          className="blog_usecase_link font-section-normal-text"
                          href="https://workativ.com/conversational-ai-platform/ms-teams-bot-workplace-support-bot"
                        >
                          MS Teams Bot - Your ultimate workplace support
                        </a>
                      </li>
                      <li>
                        <a
                          className="blog_usecase_link font-section-normal-text"
                          href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot"
                        >
                          What Is Conversational AI? A Guide to Conversational
                          AI Chatbots
                        </a>
                      </li>
                      <li>
                        <a
                          className="blog_usecase_link font-section-normal-text"
                          href="https://workativ.com/conversational-ai-platform/build-or-buy-virtual-agent"
                        >
                          Build or buy Virtual Agent
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <RequestForm isFooterForm={true} />
            </Layout>
          </Container>
        </TabProvider>
      </section>
    </>
  );
}

const featureComponents = {
  header: (
    <h4 className="heading_feature font-section-header">
      Workplace Support Bot Capabilities
    </h4>
  ),
  mobileHeader: (
    <h4 className="heading_mob font-section-sub-header">
      Workplace Support Bot Capabilities
    </h4>
  ),
  sliders: [
    {
      title: "Workplace Support Bot",
      desktopComponent: (
        <>
          <div className="right_header_content padding-top-15 mr-8">
            <h5 className="font-section-sub-header">
              Create a Workplace Support Bot with no coding
            </h5>
            <p className="font-section-normal-text">
              Workativ enables you to build chatbots in minutes. Add, remove or
              optimize new topics for the chatbot easily based on your
              requirement. Use our no-code canvas to create engaging employee
              experiences.
            </p>

            <p className="font-section-normal-text">
              {" "}
              Go digital – easier, faster and smarter employee support.
            </p>
          </div>
          <img
            className=""
            src={AllFeatureImg1}
            alt="Workplace Support Bot"
          ></img>
        </>
      ),

      mobileComponent: (
        <>
          {" "}
          <h5 className="font-section-sub-header">
            Create a Workplace Support Bot with no coding
          </h5>
          <p className="font-section-normal-text">
            Workativ enables you to build chatbots in minutes. Add, remove or
            optimize new topics for the chatbot easily based on your
            requirement. Use our no-code canvas to create engaging employee
            experiences.
          </p>
          <p className="font-section-normal-text mb-0">
            {" "}
            Go digital – easier, faster and smarter employee support.
          </p>
          <img
            className="mob_feature_img"
            src={AllFeatureImg1}
            alt="Workplace Support Bot"
          ></img>
        </>
      ),
    },
    {
      title: "App Workflow Automation",
      type: "slider",
      desktopComponent: () => (
        <>
          <div class="right_header_content mr-8">
            <SectionWithImageTabs slides={slides} />
          </div>
        </>
      ),
      mobileComponent: () => (
        <>
          <div class="right_header_content mr-8 mobile-section-with-tabs">
            <SectionWithImageTabs slides={slides} />
          </div>
        </>
      ),
    },

    {
      title: "Bot Marketplace",
      desktopComponent: (
        <>
          <div className="right_header_content padding-top-15 mr-8">
            <h5 className="font-section-sub-header">
              Add pre-built Workflows to Workplace Support Bot
            </h5>
            <p className="font-section-normal-text">
              Workativ comes with over 600+ pre-built app workflow automations
              for popular apps like ITSM, HR, MDM, Access Management, Knowledge
              Management and so on, that are ready to use from our workflow
              marketplace. No coding.
            </p>
            <p>Just download, connect, and go live instantly.</p>
          </div>
          <img
            className=""
            src={AllFeatureImg3}
            alt="Workplace Support Bot Workflows Marketplace"
          ></img>
        </>
      ),

      mobileComponent: (
        <>
          {" "}
          <h5 className="font-section-sub-header">
            Add pre-built Workflows to Workplace Support Bot
          </h5>
          <p className="font-section-normal-text">
            Workativ comes with over 600+ pre-built app workflow automations for
            popular apps like ITSM, HR, MDM, Access Management, Knowledge
            Management and so on, that are ready to use from our workflow
            marketplace. No coding.
          </p>
          <p className="font-section-normal-text mb-0">
            Just download, connect, and go live instantly.
          </p>
          <img
            className="mob_feature_img"
            src={AllFeatureImg3}
            alt="Workplace Support Bot Workflows Marketplace"
          ></img>
        </>
      ),
    },

    {
      title: "Ominichannel",
      desktopComponent: (
        <>
          <div className="right_header_content padding-top-15 mr-8">
            <h5 className="font-section-sub-header">
              Scale your support with Workplace Support Bot on Slack, Teams, or
              a Chat Widget
            </h5>
            <p className="font-section-normal-text">
              Companies using Workplace Support Bot have seen to automate 60% of
              issues & requests, and scale support much faster.
            </p>

            <p className="font-section-normal-text">
              {" "}
              Delight your employees with always-on 24/7, follow-me,
              auto-resolution chatbot, from the comfort of their favorite apps
              like Slack, Teams, or a Chat Widget.
            </p>
          </div>
          <img
            className=""
            src={AllFeatureImg4}
            alt="Workplace Support Bot Ominichannel"
          ></img>
        </>
      ),

      mobileComponent: (
        <>
          {" "}
          <h5 className="font-section-sub-header">
            Scale your support with Workplace Support Bot on Slack, Teams, or a
            Chat Widget
          </h5>
          <p className="font-section-normal-text">
            Companies using Workplace Support Bot have seen to automate 60% of
            issues & requests, and scale support much faster.
          </p>
          <p className="font-section-normal-text mb-0">
            {" "}
            Delight your employees with always-on 24/7, follow-me,
            auto-resolution chatbot, from the comfort of their favorite apps
            like Slack, Teams, or a Chat Widget.
          </p>
          <img
            className="mob_feature_img"
            src={AllFeatureImg4}
            alt="Workplace Support Bot Ominichannel"
          ></img>
        </>
      ),
    },

    {
      title: "Agent Handover",
      desktopComponent: (
        <>
          <div className="right_header_content padding-top-15 mr-8">
            <h5 className="font-section-sub-header">Live Agent Handover</h5>
            <p className="font-section-normal-text">
              Workativ offers seamless{" "}
              <span className="font-section-normal-text-medium color_black_span">
                {" "}
                'agent handover’{" "}
              </span>{" "}
              of chatbot to live agents with complete context and conversation
              history so your agents can pick up right where the bot left,
              avoiding any user frustration – but most importantly solving
              end-user issues right away.
            </p>
          </div>
          <img
            className=""
            src={AllFeatureImg5}
            alt="Workplace Support Bot Agent Handover"
          ></img>
        </>
      ),

      mobileComponent: (
        <>
          {" "}
          <h5 className="font-section-sub-header">Live Agent Handover</h5>
          <p className="font-section-normal-text mb-0">
            Workativ offers seamless{" "}
            <span className="font-section-normal-text-medium color_black_span">
              {" "}
              'agent handover’{" "}
            </span>{" "}
            of chatbot to live agents with complete context and conversation
            history so your agents can pick up right where the bot left,
            avoiding any user frustration – but most importantly solving
            end-user issues right away.
          </p>
          <img
            className="mob_feature_img"
            src={AllFeatureImg5}
            alt="Workplace Support Bot Agent Handover"
          ></img>
        </>
      ),
    },
  ],
};

const onboardoingSliderData = {
  header: {
    desktop: (
      <h2 className="font-section-header">
        Go live in minutes with our onscreen onboarding
      </h2>
    ),
    mobile: (
      <h3 className="font-section-header">
        Go live in minutes with our onscreen onboarding
      </h3>
    ),
  },
  subHeader: {
    desktop: (
      <h5 className="font-section-normal-text-medium sign_up_font_size_reduce">
        Sign up for FREE Bot
        <a
          style="color: #1857DC;"
          href="https://assistant.workativ.com/authentication/u/direct-signup"
        >
          {" "}
          here
        </a>
      </h5>
    ),
    mobile: (
      <h5 className="font-section-normal-text-medium sign_up_font_size_reduce">
        Sign up for FREE Bot{" "}
        <a
          style="color: #1857DC;"
          href="https://assistant.workativ.com/authentication/u/direct-signup"
        >
          {" "}
          here
        </a>
      </h5>
    ),
  },
  sliders: [
    {
      title: "Sign up",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Sign up
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                You can create a new bot or download pre-built Workplace Support
                Bot from marketplace.
              </p>
            </div>
          </div>

          <div className="right_section">
            <img src={OnboardSlide} alt="sign_up"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="font-section-sub-header">Sign up</h3>
            <p className="font-section-normal-text">
              Go live in minutes with our onscreen onboarding
            </p>
          </div>

          <div className="image_section">
            <img src={OnboardSlide} alt="sign_up"></img>
          </div>
        </div>
      ),
    },
    {
      title: "Download Bot",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Download Workplace Support Bot
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                Bot templates are pre-built bots with conversations covering
                most common and repetitive support topics. Easily download
                Workplace Support Bot in one-click, add new or edit existing
                support topics in minutes.
              </p>
            </div>
          </div>

          <div className="right_section">
            <img src={OnboardSlide1} alt="bot_marketplace"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="onboarding_slider_content_header font-section-sub-header">
              Download Workplace Support Bot
            </h3>
            <p className="responsive-p-slider font-section-normal-text">
              Bot templates are pre-built bots with conversations covering most
              common and repetitive support topics. Easily download Workplace
              Support Bot in one-click, add new or edit existing support topics
              in minutes.
            </p>
          </div>

          <div className="image_section">
            <img src={OnboardSlide1} alt="bot_marketplace"></img>
          </div>
        </div>
      ),
    },
    {
      title: "Connect App Workflows",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Connect App Workflows
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                Connecting chatbot with your apps and building app workflows
                requires a lot of development efforts, time, and costs. But
                workativ provides pre-built app integrations and app workflows
                so you can connect your chatbot with your app and automate tasks
                instantly. No coding.
              </p>
            </div>
          </div>

          <div className="right_section">
            <img src={OnboardSlide2} alt="connect_app_work"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="onboarding_slider_content_header font-section-sub-header">
              Connect App Workflows
            </h3>
            <p className="responsive-p-slider font-section-normal-text">
              Connecting chatbot with your apps and building app workflows
              requires a lot of development efforts, time, and costs. But
              workativ provides pre-built app integrations and app workflows so
              you can connect your chatbot with your app and automate tasks
              instantly. No coding.
            </p>
          </div>

          <div className="image_section">
            <img src={OnboardSlide2} alt="connect_app_work"></img>
          </div>
        </div>
      ),
    },
    {
      title: "Test your Bot",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Bot Testing
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                Test your bot in real time using “Try Me”. Make adjustments, add
                or edit conversations, fine tune user interactions, and review
                your bot conversations on the go before you go-live.
              </p>
            </div>
          </div>
          <div className="right_section">
            <img src={OnboardSlide3} alt="bot_testing"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="onboarding_slider_content_header font-section-sub-header">
              Bot Testing
            </h3>
            <p className="responsive-p-slider font-section-normal-text">
              Test your bot in real time using “Try Me”. Make adjustments, add
              or edit conversations, fine tune user interactions, and review
              your bot conversations on the go before you go-live.
            </p>
          </div>

          <div className="image_section">
            <img src={OnboardSlide3} alt="bot_testing"></img>
          </div>
        </div>
      ),
    },
    {
      title: "Go Live",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Deploy on Teams, Slack, or as Chat Widget
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                Turn your favorite collaboration app into modern employee
                self-service by deploying chatbot on your Teams, Slack or as
                Widget on your self-help portal in few clicks. Customize the
                end-user experience by applying your company branding, logo etc.
              </p>
              <div className="icons_container_onboarding">
                <div className="icon_text">
                  <img
                    class="slack_feature_icon_onboarding"
                    src={FeatureSlack}
                  ></img>
                  <h6 className="font-section-small-text-medium  mb-0 pt-1">
                    Slack
                  </h6>
                </div>

                <div className="icon_text">
                  <img
                    class="teams_feature_icon_onboarding"
                    src={FeatureTeams}
                  ></img>
                  <h6 className="font-section-small-text-medium mb-0 pt-1">
                    Teams
                  </h6>
                </div>
                <div className="icon_text">
                  <img
                    class="chat_feature_icon_onboarding"
                    src={FeatureChat}
                  ></img>
                  <h6 className="font-section-small-text-medium  mb-0 pt-1">
                    Chat Widget
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="right_section">
            <img src={OnboardSlide4} alt="deploy_teams_slack_chat"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="onboarding_slider_content_header font-section-sub-header">
              Deploy on Teams, Slack, or as Chat Widget
            </h3>
            <p className="responsive-p-slider font-section-normal-text">
              Turn your favorite collaboration app into modern employee
              self-service by deploying chatbot on your Teams, Slack or as
              Widget on your self-help portal in few clicks. Customize the
              end-user experience by applying your company branding, logo etc.
            </p>
            <div className="icons_container_onboarding_mobile">
              <img
                class="slack_feature_icon_onboarding_mobile"
                src={FeatureSlack}
              ></img>
              <h6>Slack</h6>

              <img
                class="teams_feature_icon_onboarding_mobile"
                src={FeatureTeams}
              ></img>
              <h6>Teams</h6>

              <img
                class="chat_feature_icon_onboarding_mobile"
                src={FeatureChat}
              ></img>
              <h6>Chat Widget</h6>
            </div>
          </div>

          <div className="image_section">
            <img src={OnboardSlide4} alt="deploy_teams_slack_chat"></img>
          </div>
        </div>
      ),
    },
  ],
};

const slides = [
  {
    image: unlock,
    title: "Unlock Account",
    tabFor: "vertical-tab-one",
  },
  {
    image: password,
    title: "Password Resets",
    tabFor: "vertical-tab-two",
  },

  {
    image: user,
    title: "User Provisioning",
    tabFor: "vertical-tab-three",
  },
  {
    image: access,
    title: "Access Management",
    tabFor: "vertical-tab-four",
  },
  {
    image: asset,
    title: "Asset Requests",
    tabFor: "vertical-tab-five",
  },
  {
    image: leave,
    title: "Apply Leave",
    tabFor: "vertical-tab-six",
  },
  {
    image: onboarding,
    title: "Onboarding",
    tabFor: "vertical-tab-seven",
  },
];

const buttonMenu = [
  {
    tabFor1: "vertical-tab-one",
    tabName: "unlock account",
  },
  {
    tabFor1: "vertical-tab-two",
    tabName: "password resets",
  },

  {
    tabFor1: "vertical-tab-three",
    tabName: "user provisioning",
  },
  {
    tabFor1: "vertical-tab-four",
    tabName: "access management",
  },
  {
    tabFor1: "vertical-tab-five",
    tabName: "asset requests",
  },
  {
    tabFor1: "vertical-tab-six",
    tabName: "apply leave",
  },
  {
    tabFor1: "vertical-tab-seven",
    tabName: "onboarding",
  },
];

export const SectionWithImageTabs = (props) => {
  // console.log("SectionWithImageTabs ::: 1", slides);
  const { slides } = props;
  const [isActive, setIsActive] = React.useState("Unlock Account");

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      incrementOffset();
    },
    onSwipedRight: () => {
      decrementOffset();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const total = slides.length; // the length of the slide array
  const enabled = true; // pauses/disables the player
  const useLoaded = false; // this allows for delayed loads like images or embeds
  const speed = 10000; // speed in milliseconds to show each slide
  const loop = true;

  const [offset, setOffset] = React.useState(0);
  const [items, setItems] = React.useState([]);

  function incrementOffset() {
    if (offset === total - 1) {
      setOffset(loop ? 0 : offset);
    } else {
      setOffset(offset + 1);
    }
  }

  function decrementOffset() {
    if (offset === 0) {
      setOffset(loop ? total - 1 : offset);
    } else {
      setOffset(offset - 1);
    }
  }

  const loaded = useLoaded ? items.length === total : true;
  // useInterval(() => {
  //   if (loaded && enabled && offset < total) {
  //     incrementOffset();
  //   }
  // }, speed);
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <TabProvider defaultTab="vertical-tab-one" vertical>
      <div>
        <h5 className="font-section-sub-header padding-top-15 margin-bottom-15">
          {" "}
          Workplace Support Bot + workflow automation = autonomous resolution
        </h5>
        <p className="desktop_tab_list font-section-normal-text">
          Workativ platform’s one-click chatbot integration with your apps and
          pre-built app workflows will enable you to deliver true employee
          service automation. Automate issues & requests{" "}
          {buttonMenu.map((menu, i) => (
            <li className="button_list_li image_slider_li font-section-normal-text-medium">
              <span
                className={isActive === menu.tabName ? "" : ""}
                onClick={() => {
                  setIsActive(menu.tabName);
                  setOffset(i);
                }}
              >
                {" "}
                {menu.content && (
                  <span className="pad_content">{menu.content}</span>
                )}
                {menu.tabFor1 ? (
                  <Tab tabFor={menu.tabFor1}>{menu.tabName}</Tab>
                ) : null}
              </span>
              <span className="space_left"> , </span>
            </li>
          ))}{" "}
          and much more
        </p>
        <p className="mobile_tab_list">
          Workativ platform’s one-click chatbot integration with your apps and
          pre-built app workflows will enable you to deliver true employee
          service automation. Automate issues & requests{" "}
          <>
            {" "}
            {buttonMenu.map((menu, i) => (
              <li className="button_list_li  image_slider_li font-section-normal-text-medium">
                <AnchorLink href={"#" + menu.tabFor1} offset={() => -330}>
                  <span
                    className={isActive === menu.tabName ? "" : ""}
                    onClick={() => {
                      setIsActive(menu.tabName);
                      setOffset(i);
                    }}
                  >
                    {" "}
                    {menu.content && (
                      <span className="pad_content">{menu.content}</span>
                    )}
                    {menu.tabFor1 ? (
                      <Tab tabFor={menu.tabFor1}>{menu.tabName}</Tab>
                    ) : null}
                  </span>
                  <span className="space_left"> , </span>
                </AnchorLink>
              </li>
            ))}
          </>
          and much more.
        </p>
      </div>
      <div className="feature_page_img_right mob_slider_pad_0 font-section-normal-text-medium">
        <div className="desktop_tabs justify-content-center desktop_tab_width_change padding-bottom-40 display-flex">
          <TabList>
            {slides.map((menu) => (
              <Tab tabFor={menu.tabFor}>{menu.title}</Tab>
            ))}
          </TabList>
          {slides.map((menu) => (
            <TabPanel tabId={menu.tabFor}>
              <img src={menu.image} alt={menu.title}></img>
            </TabPanel>
          ))}
        </div>
        <div className="slider_wrapper">
          <div
            className="slider_container"
            id={slides[offset].tabFor}
            {...handlers}
          >
            <img src={slides[offset].image} alt={slides[offset].title} />
          </div>
          <div className="controls_wrapper">
            <span
              className="controls_left"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                decrementOffset();
              }}
            >
              {isSmall ? (
                <MobNextArrowIcon />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g id="left_arrow" transform="translate(-20 -1622)">
                    <circle
                      id="Ellipse_254"
                      data-name="Ellipse 254"
                      cx="12"
                      cy="12"
                      r="12"
                      transform="translate(20 1622)"
                      fill="#fff"
                    />
                    <g
                      id="Group_6414"
                      data-name="Group 6414"
                      transform="translate(-396 -258)"
                    >
                      <g
                        id="Group_6413"
                        data-name="Group 6413"
                        transform="translate(424 1886)"
                      >
                        <path
                          id="Path_3332"
                          data-name="Path 3332"
                          d="M430.629,1898.239a.508.508,0,0,1-.344-.134l-6.119-5.609a.51.51,0,0,1,0-.752l6.119-5.609a.51.51,0,0,1,.689.752l-5.709,5.234,5.709,5.233a.51.51,0,0,1-.345.886Z"
                          transform="translate(-424 -1886)"
                          fill="#2c7357"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              )}
            </span>
            <span className="font-section-normal-text-medium  padding-top-10">
              {slides[offset].title}
            </span>
            <span
              className="controls_right"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                incrementOffset();
              }}
            >
              {isSmall ? (
                <MobNextArrowIcon />
              ) : (
                <svg
                  id="right_arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <circle
                    id="Ellipse_254"
                    data-name="Ellipse 254"
                    cx="12"
                    cy="12"
                    r="12"
                    fill="#fff"
                  />
                  <g
                    id="Group_6414"
                    data-name="Group 6414"
                    transform="translate(8.861 6)"
                  >
                    <g id="Group_6413" data-name="Group 6413">
                      <path
                        id="Path_3332"
                        data-name="Path 3332"
                        d="M424.51,1898.239a.508.508,0,0,0,.344-.134l6.119-5.609a.51.51,0,0,0,0-.752l-6.119-5.609a.51.51,0,0,0-.689.752l5.709,5.234-5.709,5.233a.51.51,0,0,0,.345.886Z"
                        transform="translate(-424 -1886)"
                        fill="#2c7357"
                      />
                    </g>
                  </g>
                </svg>
              )}
            </span>
          </div>
        </div>
      </div>
    </TabProvider>
  );
};
